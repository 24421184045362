@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f7f9;
}

button:focus {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
a:hover {
  text-decoration: none;
}

button *,
button *:hover {
  text-decoration: none;
}

.searchBar {
  border-radius: 3em !important;
  height: 42px !important;
}

.searchBar>div {
  margin: auto 22px;
}

th span div div {
  align-items: center;
}

th span {
  cursor: pointer;
}

th>div {
  cursor: not-allowed;
}

input:disabled {
  background-color: #00000009;
  cursor: not-allowed;
  border-radius: 0.5em;
}

.rencontre-datatable {
  border-radius: 0em !important;
}

.young_veteran,
.young_veteran td {
  font-weight: 500;
  background: #78c8ff !important;
  color: white
}

.young_veteran input,
.young_veteran select,
.young_veteran div {
  color: white !important;
}

.rencontre-datatable th[data-colindex="2"],
.rencontre-datatable tr::not(.young_veteran) td[data-colindex="2"] {
  background-color: #e6f1ff95 !important;
}

.rencontre-datatable th[data-colindex="3"],
.rencontre-datatable tr:not(.young_veteran) td[data-colindex="3"] {
  background-color: #fff20030 !important;
}

.rencontre-datatable th[data-colindex="4"],
.rencontre-datatable tr:not(.young_veteran) td[data-colindex="4"] {
  background-color: #80ffff45 !important;
}

.rencontre-datatable th[data-colindex="5"],
.rencontre-datatable tr:not(.young_veteran) td[data-colindex="5"] {
  background-color: #ff808040 !important;
}

.rencontre-datatable th[data-colindex="6"],
.rencontre-datatable tr:not(.young_veteran) td[data-colindex="6"] {
  background-color: #f9cdaa75 !important;
}

.rencontre-datatable th[data-colindex="7"],
.rencontre-datatable tr:not(.young_veteran) td[data-colindex="7"] {
  background-color: #e6d1c150 !important;
}

.rencontre-datatable th[data-colindex="8"],
.rencontre-datatable tr:not(.young_veteran) td[data-colindex="8"] {
  background-color: #d8d8d850 !important;
}

.table-stats {
  width: 100%;
  font-size: 12px;
  table-layout: fixed;
  border-collapse: collapse;
}

.table-stats td {
  background-color: white;
}

.table-stats td,
.table-stats th {
  padding: 0.75em;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.table-stats th {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.chrome-picker {
  box-shadow: none !important;
}

.ligne_suspendue {
  background-color: red;
}

.ligne_suspendue td,
.ligne_suspendue td * {
  color: white !important;
}

/* Sélectionnez le conteneur du multiselect Material-UI */
.MuiSelect-root {
  display: flex;
  /* Utilisez flexbox pour positionner les chips */
  flex-wrap: wrap;
  /* Permet aux chips de passer à la ligne suivante si nécessaire */
  gap: 8px;
  /* Ajoute un espace entre les chips */
  align-items: center;
  /* Centre les éléments verticalement */
}

/* Style pour les chips à l'intérieur du multiselect */
.MuiChip-root {
  margin-bottom: 8px;
  /* Espace en dessous de chaque Chip */
}

.MuiSelect-selectMenu>div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
  height: auto;
  white-space: normal;
  flex-wrap: wrap;
}